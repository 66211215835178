<template>
  <a-modal
      v-model="showModal"
      :width='1000'
      :title="editorTitle"
  >
    <template #footer>
      <div v-if="!deleteStatus">
        <a-button key="back" @click="handleCanCelEditor">取消</a-button>
        <a-button key="submit" type="primary" @click="handleConfirmEditor">确定</a-button>
      </div>
      <div v-else></div>
    </template>
    <div class="modal-content">
      <div class="modal-tag-list">
        <div class="modal-tag-item" v-for="item in listData" :key="item.versionId">
          <div class="tag-item-box-text" v-if="!item.isShowSelected">
            <div class="item-text-box">{{ item.relCoinKindItemName + '·' + item.relCoinVersionName }}</div>
            <div class="item-icon">
              <a-icon v-if="!deleteStatus" type="edit" class="color-blue font-size-24 cur-pot" @click="handleEditorItem(item)"/>
              <a-popconfirm
                  :title="`确定删除【${item.relCoinKindItemName + '·' + item.relCoinVersionName}】关联？`"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="handleDeleteItem(item)"
              ><a-icon v-if="deleteStatus" type="close-circle" class="color-red font-size-24 cur-pot ml-20" />
              </a-popconfirm>
            </div>
          </div>
          <div class="tag-item-box-selected" v-else>
            <div class="item-selected-box">
              <a-select
                  class="w-100"
                  size="small"
                  v-model="item.relCoinKindItemId"
                  placeholder="小币种"
                  allowClear
                  @change="handleChangeLittleCoinCoinId(item)"
              >
                <a-select-option
                    v-for="smallCoin of searchLittleCoins"
                    :key="smallCoin.id"
                    :value="smallCoin.id"
                >{{ smallCoin.coinKindItemName }}</a-select-option>
              </a-select>
              <a-select
                  class="w-250 ml-5"
                  size="small"
                  v-model="item.relCoinVersionId"
                  placeholder="选择版别"
                  allowClear
              >
                <a-select-option
                    v-for="version of versionList"
                    :key="version.id"
                    :value="version.id"
                >{{ version.coinKindVersionName }}</a-select-option>
              </a-select>
            </div>
            <div>
<!--              <a-icon type="close-circle" class="color-red font-size-24 cur-pot ml-20" @click="handleEditorItem(item, 'close')"/>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils";
import {
  addOrEditorKaiYuanByFrontList,
  deleteKaiYuanByFrontList
} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontManage/_apis"
export default {
  data() {
    return {
      untils,
      showModal: false,
      editorTitle: '编辑关联',
      aboutId: '',
      modalData: '',
      listData: [],
      searchLittleCoins: [],
      // 版别数组
      versionList: [],
      params: {
        id: '',
        coinKindId: 1,
        coinKindItemId: 749,
        coinVersionId: undefined,
        subList: []
      },
      deleteStatus: false,
    };
  },
  methods: {
    /** 确定编辑 */
    async handleConfirmEditor() {
      const foundIndex = this.listData.findIndex(el => !el.relCoinKindItemId || !el.relCoinVersionId)
      if (foundIndex >= 0) return this.$message.warn(`第${foundIndex + 1}个数据小币种或版别不能为空`)
      let temp = []
      this.listData.forEach(el => {
        temp.push({
          id: el.id,
          relCoinKindId: 1,
          relCoinKindItemId: el.relCoinKindItemId,
          relCoinVersionId: el.relCoinVersionId,
        })
      })
      this.params.subList = temp
      this.$loading.show()
      const res = await addOrEditorKaiYuanByFrontList(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.$emit('success')
      this.showModal = false
    },

    async show(data, type) {
      if (type) this.deleteStatus = true
      this.params.coinVersionId = data.coinVersionId
      this.params.id = data.id
      const tempList = data.subList
      tempList.forEach(el => {
        el.isShowSelected = false
      })
      this.listData = tempList
      this.showModal = true;
    },

    /** 取消编辑 */
    handleCanCelEditor() {
      this.listData = []
      this.searchLittleCoins = []
      this.versionList = []
      this.params = {
        id: '',
        coinKindId: 1,
        coinKindItemId: 749,
        coinVersionId: undefined,
        subList: []
      }
      this.deleteStatus = false,
      this.showModal = false
    },

    /** 编辑当前item */
    async handleEditorItem(item, type) {
      this.listData.forEach(el => {
        if (el.id === item.id) {
          el.isShowSelected = !el.isShowSelected
        }
      })
      if (!type) await this.searchLittleCoin(item, 'once')
      this.$forceUpdate()
    },
    /** 搜索小币种 */
    async searchLittleCoin(item, type) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/kinditem/list", {
        params: { coinId: 1, pageSize: 500},
      });
      this.$loading.hide()
      const { records } = res.data;
      this.searchLittleCoins = records.filter(el => el.id !== 749);
      await this.handleChangeLittleCoinCoinId(item, type)
    },
    /** 选择小币种之后搜索有多少个版别 */
    async handleChangeLittleCoinCoinId(item, type) {
      if (!type) {
        this.listData.forEach(el => {
          if (item.id === el.id) {
            el.relCoinVersionId = undefined
          }
        })
      }
      this.$loading.show()
      const res = await this.axios("/dq_admin/kinditemversion/list2", {
        params: {
          pageNum: 1,
          pageSize: 1000,
          coinId: 1,
          coinItemId: item.relCoinKindItemId
        },
      });
      this.$loading.hide()
      if (res.status !== '200') return
      const { records } = res.data;
      // this.versionList = records.map(el => {
      //   return {relCoinVersionId: el.id, name: el.coinKindVersionName, selected: false}
      // })
      this.versionList = records
    },



    /** 删除当前Item */
    async handleDeleteItem(item) {
      this.$loading.show()
      const res = await deleteKaiYuanByFrontList({id: item.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.$emit('success')
      this.handleCanCelEditor()
    }
  }
};
</script>
<style lang="scss" scoped>
.tag-item-box-selected {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-text-box {
  padding: 5px 20px;
  font-weight: bold;
  font-size: 20px;
}
.tag-item-box-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-tag-item {
  margin-right: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.modal-tag-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.modal-content {
  width: 100%;
}
</style>